import React from "react";
import { Link } from "gatsby";

import { PublicLayout } from "../layouts/public";


const Page = (props) => {
    const content = {}
    content.htmlTitle = `The Quality Data Co.`
    content.pageTitle = (<div>Data well with Yolk.</div>)
    content.pageTagline = (<div>Data Quality Rocket Fuel.</div>)
    content.pageBody = (
        <React.Fragment>
            <div>Yolk is a data warehouse, ELT pipe, and DataOps platform. <br/>We save you time and money, speed up time-to-value, and boost the quality of your data.</div>
            <div className="cta">
            <a href="https://airtable.com/shrnkzW00Jfxx5Nm4" target="_blank" className="primaryCta">See how it works</a>
            </div>
            <br/><br/><br/><br/><br/>
            {/* <div className="section">
                <div className="h2">Not just another CDP, Pipe, or Analytics solution.</div>
                <div>
                The market is spoilt (overwhelmed?) with good-enough technologies at each layer of the data stack. At Yolk, we're not just replicating or combining existing functionality and making it easier to implement and evolve your stack–our mission is to ameliorate the quality of your data. This means our customers not only get a great pipe and warehouse, they get peace of mind and proprietary tooling that empowers them to maximize data quality and ultimately ROI. Focus on maximizing ROI, not on wrangling your data.
                </div>
            </div>
            <div className="section">
                <div className="h2">Not just a pipe and warehouse bundle, either.</div>
                <div>
                Sure, we've combined warehousing with CDP and ETL/ELT functionality, and yes, this will save you time and money. But Yolk is more than just that. Our mission is to ameliorate the quality of data that forms good decisions, targeting, and ML. Combining a warehouse and pipe not only saves time and money, it fundamentally boosts the quality of your data and gives you more control and transparency over it.
                </div>
            </div>
            <div className="cta">
                <Link to="/platform/demo">Find Out More</Link>
            </div> */}
        </React.Fragment>
    )

    return <PublicLayout {...content} />;
};

export default Page;
